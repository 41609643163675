import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

const Resume = () => (

<div className="bg-gray-700 pb-12">
  <div className="container py-16 lg:pb-16">
    <h3 className="text-2xl sm:text-3xl text-gray-100">
        <span className="spany pee font-bold text-gray-200">Past Work</span>
    </h3>
    <button className="text-gray-600">Download Resume PDF</button>

    <div className="mt-8">
        <div className="text-gray-400">
        <h3 className="text-2xl text-gray-200 font-semibold">Dad <span className="text-gray-500">@ 👶 Home </span></h3>
        <p className="pb-1 text-sm text-gray-500">Austin, TX</p>
          <p>May 2021 - Present</p>
          <div className="" />
        </div>
        <div className="text-gray-600">

            <p>
            I've been spending my days with my son and my nights picking up a bit of freelance work (mostly Javascript/React-related but some product/design projects as well).
            </p>
        </div>
      </div>
      <div className="mt-8">
        <div className=" text-gray-400">
        <h3 className="text-2xl text-gray-200 font-semibold">Director of Product <span className="text-gray-500">@ 
            <img className="w-6 mr-1 ml-1 inline" src="https://res.cloudinary.com/crunchbase-production/image/upload/c_lpad,h_256,w_256,f_auto,q_auto:eco,dpr_1/v1472677682/adwa3r766aoyfcsdwohv.png" />
            <a href="https://patientpattern.com" className="ae">Patient Pattern </a></span>
        </h3> 
        <p className="pb-1 text-sm text-gray-500">Austin, TX</p>
          <p>October 2019 - May 2021</p>
          <div className="" />
        </div>
        <div className="text-gray-600">

            <p>
            Led risk projection product suite from ~$100k/yr to $4.5mm/yr revenue in the post-acute long-term care space. Led all product, design, and marketing functions/teams.
            </p>
        </div>
      </div>
      <div className="mt-8">
      <div className="text-gray-400">     
      <h3 className="text-2xl text-gray-200 font-semibold">Senior Product Manager <span className="text-gray-500">@ 
      <img className="w-6 mr-1 ml-2 inline" src="https://lh3.googleusercontent.com/q_4kwvN64YsnmaoJH8rOp1Fu9AE2uJXOIgkP_vPZSpzL-2ypcYIjErrGuq6E6zV18pQ=w300" />
      <a href="https://reviewtrackers.com" className="ae">ReviewTrackers</a></span></h3>
      <p className="pb-1 text-sm text-gray-500">Chicago, IL</p>
      <p>June 2017 - July 2018</p>
          <div className="" />
        </div>
        <div className="text-gray-600">
        <ul>
          <li>• Lead strategy and execution for all of ReviewTrackers' data and insights products (NLP, ML, analytics, reporting).</li>
          <li>• Prioritize roadmap and release cadence for Mobile products (iOS/Android applications using React Native).</li>
          <li>• Help drive the future of our business thru value-added features based on client needs and business opportunities.</li>
          <li>• Coordinate qualitative (usability/qa/uat) and quantitative (a/b &amp; multivariate) testing efforts.</li>
          <li>• Work closely with all internal teams to promote organizational alignment and consistent messaging.</li>
        </ul>
        </div>
      </div>
      <div className="mt-8">
      <div className="text-gray-400">
      <h3 className="text-2xl text-gray-200 font-semibold">Product Manager <span className="text-gray-500">@ 
      <img className="w-5 mr-2 ml-2 inline" src="https://1ststepaccounting.com/wp-content/uploads/2017/07/spothero-logo.png" />
      <a href="https://spothero.com" className="ae">SpotHero</a></span></h3>
      <p className="pb-1 text-sm text-gray-500">Chicago, IL</p>
      <p>December 2015 - July 2017</p>
          <div className="" />
        </div>
        <div className="text-gray-600">
          <p>Consumer Products -- Product Manager (Sept 2016 - June 2017)</p>
          <ul>
            <li>• Formulate strategy and lead prioritization for all of SpotHero's consumer-facing products (iOS/Android/SpotHero.com/m.SpotHero.com).</li>
            <li>• Prioritize roadmap and release cadence.</li>
            <li>• Help drive the future of our business through value-added features.</li>
            <li>• Ensure the delivery quality of all Consumer facing products.</li>
            <li>• Coordinate qualitative (usability) and quantitative (a/b &amp; multivariate) testing efforts.</li>
            <li>• Work closely with all internal teams to promote organizational alignment.</li>
            </ul>
          <br />  
          <p>Mobile Apps --Product Manager (Dec 2015 - Sept 2016)</p>
          <ul>
            <li>• Formulate native app strategy.</li>
            <li>• Prioritize roadmap and release cadence.</li>
            <li>• Help drive the future of our business.</li>
            <li>• Ensure the delivery quality of iOS/Android products.</li>
            <li>• Consume snacks and sparkling water.</li>
          </ul>
        </div>
      </div>
      <div className="mt-8">
      <div className="text-gray-400">
      <h3 className="text-2xl text-gray-200 font-semibold">Product Manager <span className="text-gray-500">@ 
      <img className="w-5 mr-1 ml-2 inline" src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/c5/Target_Corporation_logo_%28vector%29.svg/600px-Target_Corporation_logo_%28vector%29.svg.png" />
      <a href="https://target.com" className="ae">Target</a></span></h3>
      <p className="pb-1 text-sm text-gray-500">Minneapolis, MN \ San Francisco, CA</p>
      <p>July 2013 - December 2015</p>
          <div className="" />
        </div>
        <div className="text-gray-600">
          <ul>
            <li>• Ensure all requirements are satisfied (functional, accessibility, analytics tagging).</li>
            <li>• Formulate user experience strategy -- shape execution with UI design.</li>
            <li>• Prioritize roadmap and release cadence.</li>
            <li>• Work efficiently with both internal and external partners.</li>
            <li>• Monitor KPIs.</li>
            <li>• Ideate, implement, and monitor AB/multivariate user testing.</li>
            <li>• Leverage usability labs, intercepts, and guest feedback channels to drive meaningful features.</li>
            <li>• Build business cases to justify planned initiatives and secure necessary funding.</li>
          </ul>
        </div>
      </div>
      <div className="mt-8">
      <div className="text-gray-400">
      <h3 className="text-2xl text-gray-200 font-semibold">Senior Business Consultant <span className="text-gray-500">@ 
      <img className="w-6 mr-1 ml-2 inline" src="https://res.cloudinary.com/crunchbase-production/image/upload/c_lpad,h_256,w_256,f_auto,q_auto:eco,dpr_1/vdwk0o32acgstcv8pvsa" />
      <a href="https://sapient.com" className="ae">SapientNitro</a></span></h3>
      <p className="pb-1 text-sm text-gray-500">Minneapolis, MN</p>
      <p>July 2013 - December 2015</p>
          <div className="" />
        </div>
        <div className="text-gray-600">
        <ul>
          <li>• Business Consultant/Analyst for mobile web optimization.</li>
          <li>• Determine operational objectives by studying business functions; gathering information; evaluating output requirements and formats.</li>
          <li>• Monitor project progress by tracking activity; resolving problems; recommending actions.</li>
          <li>• Prepare technical reports by collecting, analyzing, and summarizing information and trends.</li>
          <li>• Translate client needs into functional requirements for offshore development.</li>
          <li>• Facilitate rigorous user acceptance testing to ensure rock solid deliverables.</li>
        </ul>
        </div>
      </div>
    </div>
  </div>

)
export default Resume
