import React from "react"

const Hero = () => (
  <div className="pb-12 pt-4">
    <div className="container py-16 lg:pb-16">
      <h1 className="text-3xl sm:text-4xl text-gray-600 font-bold">
        I'm a Product Manager, Developer, Designer & Dad.
      </h1>
      <p className="text-xs text-gray-500 py-1"><span className="text-white"> </span><i>I do some other things too.</i></p>
    </div>
  </div>
)

export default Hero
