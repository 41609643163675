import { graphql } from "gatsby"
import React from "react"
import Cards from "../components/Cards"
import Hero from "../components/Hero"
import Resume from "../components/Resume"
import Layout from "../layouts/Layout"
import Newsletter from "../components/Newsletter"
import SiteMetadata from "../components/SiteMetadata"

const IndexPage = ({ data }) => {
  return (
    <Layout>
      <SiteMetadata
        title="Resume"
        description="Online Resume of Corey Kellgren"
      />

      <Hero />
      <div className="bg-gray-800">
        <div className="container py-12 lg:py-16">
          <h3 className="text-2xl sm:text-3xl text-gray-100">
            <span className="text-gray-600">I like building things with: </span>
            <a className="ae" href="#">
              React
            </a>
            , <span className="ae">Gatsby</span>,{" "}
            <span className="ae">GraphQL</span>,{" "}
            <span className="ae">React Native</span>,{" "}
            <span className="ae">Django</span>,{" "}
            <span className="ae">Contentful</span>,{" "}
            <span className="ae">Firebase</span>,{" "}
            <span className="ae">Tailwind.css</span>,{" "}
            <span className="ae">Netlify</span> & more.
          </h3>
        </div>
      </div>
      {/* <div className="bg-gray-700 py-12 lg:py-16">
        {data.portfolio && data.portfolio.nodes.length > 0 ? (
          <Cards items={data.portfolio.nodes} />
        ) : (
          <div className="container">No projects found.</div>
        )}
      </div> */}
      <Resume />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query HomeQuery {
    portfolio: allContentfulPortfolio {
      nodes {
        ...PortfolioCard
      }
    }
  }
`
